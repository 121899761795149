import React from 'react';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import ApiProvider from 'modules/Api/ApiContext';
import { AppProvider } from 'store/AppContext';
import './index.css';

const Root = React.lazy(() => import('containers/Root'));

ReactDOM.createRoot(document.getElementById('root')).render(
    <AppProvider>
        <ApiProvider>
            <BrowserRouter>
                <Root />
            </BrowserRouter>
        </ApiProvider>
    </AppProvider>
);
