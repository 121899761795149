import React, { createContext, useContext, useMemo } from 'react';

import _get from 'lodash/get';

import { fetchJitAccessTokenAPINew, getPartnerAPI } from './auth';

const ApiContext = createContext();

export const useApi = () => {
    return useContext(ApiContext);
};

// eslint-disable-next-line react/prop-types
function ApiProvider({ children }) {
    const fetchData = async partnerId => {
        try {
            const response = await fetchJitAccessTokenAPINew({ partnerId });
            const accessToken = _get(response, 'data.token', '');
            const partner = await getPartnerAPI({ accessToken, partnerId });
            return {
                accessToken,
                partner: _get(partner, 'data', {}),
            };
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error fetching data:', error);
            throw error;
        }
    };
    const apiValue = useMemo(() => ({ fetchData }), []);

    return (
        <ApiContext.Provider value={apiValue}>{children}</ApiContext.Provider>
    );
}

export default ApiProvider;
