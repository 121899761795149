import axios from 'axios';

import {
    VITE_JIT_LOGIN_URL,
    VITE_PARTNER_URL,
    VITE_REQUEST_OTP_URL,
    VITE_VERIFY_OTP_URL,
} from 'constants/env.js';

export const fetchJitAccessTokenAPI = async () => {
    const response = await axios.post(VITE_JIT_LOGIN_URL, {
        is_guest: true,
        partner_id: 9,
    });
    return response.data;
};

export const fetchJitAccessTokenAPINew = async ({ partnerId }) => {
    return axios.post(VITE_JIT_LOGIN_URL, {
        is_guest: true,
        partner_id: partnerId,
    });
};

export const getPartnerAPI = async ({ accessToken, partnerId }) => {
    return axios.get(VITE_PARTNER_URL + partnerId, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
};

export const requestOTPAPI = async (payload, enableOTP) => {
    if (enableOTP === 'true') {
        const response = await axios.post(VITE_REQUEST_OTP_URL, {
            type: 'wma-otp',
            action: 'new',
            phone_number: `+1${payload}`,
        });
        return response.data;
    }
    await new Promise(resolve => {
        setTimeout(resolve, 1000); // 10000 milliseconds = 10 seconds
    });

    const mockResponseData = {
        data: {
            otp: 'Ea31Vw',
        },
    };

    return mockResponseData;
};

export const verifyOTPAPI = async ({ inputValue, phoneNumber }) => {
    const response = await axios.post(VITE_VERIFY_OTP_URL, {
        otp: inputValue,
        phone_number: `+1${phoneNumber}`,
    });
    return response.data;
};
